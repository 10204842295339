import { PROCESSOR, VISIBILITY_MAPPING, WASM_ACTION, WASM_PROP, INC_MOVEMENT } from './wasm_controller_const';
import { ColorConverter } from '../../../common/utils/color-converter';
import { ETreatmentPlanStage } from '../wasm-constants';
import { TransformationPipe } from '../../../common/validation/validation-utils';

export class WasmViewerController {
  /**
   * Controller for interacting with the wasm viewer API
   * @param ccall {Function} usually it's a Module.ccall function for native function call
   * @param [logger] {{
   *   debug: (...data: any[]) => void,
   *   log: (...data: any[]) => void,
   *   warn: (...data: any[]) => void,
   *   error: (...data: any[]) => void,
   * }} console compatible logger
   */
  constructor(ccall, logger) {
    this.ccall = ccall ?? Module?.ccall;
    this.logger = logger;
    this.requestTransformPipe = new TransformationPipe(this._prepareRequest);
    this.responseTransformPipe = new TransformationPipe(this._parseResponse);
  }

  _getCcall = () => {
    if (this.ccall) {
      return this.ccall;
    }

    if (Module?.ccall) {
      return this.ccall = Module.ccall;
    }
  };

  _prepareRequest = (req) => JSON.stringify(req);
  _parseResponse = (res) => {
    try {
      return JSON.parse(res);
    } catch (e) {
      this.logger?.error('Error _prepareResponse():', e);
      return null;
    }
  };

  /**
   * Calls native method with passed request object
   * @param req {Object} request object to be passed to native ccall
   * @returns {Object}
   */
  execute = (req) => {
    try {
      const preparedRequest = this.requestTransformPipe.transform(req);
      const res = this._getCcall()('executeCommand', 'string', ['string'], [preparedRequest]);
      return this.responseTransformPipe.transform(res);
    } catch (e) {
      this.logger?.error('Error WasmViewerController.execute():', e);
    }
  };

  /**
   * Retrieves wasm processor state by its name
   * @param processor {String} processor name for retrieving
   * @returns {Object}
   */
  getRepresentation = (processor) => {
    try {
      const res = this._getCcall()('getProcessorRepresentation', 'string', ['string'], [processor]);
      return this.responseTransformPipe.transform(res);
    } catch (e) {
      this.logger?.error('Error WasmViewerController.getRepresentation():', e);
    }
  };

  /**
   * Retrieves currently available processors on the wasm end
   * @returns {String[]}
   */
  availableProcessors = () => {
    try {
      const res = this._getCcall()('getAvailableProcessors', 'string', [], []);
      return this.responseTransformPipe.transform(res);
    } catch (e) {
      this.logger?.error('Error WasmViewerController.availableProcessors():', e);
    }
  };

  /**
   * Retrieves usage examples of specified processor or all if none specified
   * @param processor {PROCESSOR || ''}
   * @returns {String}
   */
  examples = (processor = '') => {
    try {
      return this._getCcall()('getProcessorExamples', 'string', ['string'], [processor]);
    } catch (e) {
      this.logger?.error('Error WasmViewerController.examples():', e);
    }
  };

  static getInstance(){
    if (!this.instance) {
      this.instance = new WasmViewerController(Module.ccall);
    }
    return this.instance;
  };
}
// it's needed for debugging purposes
window.WasmViewerController = WasmViewerController;

const ESTIMATED_CONTROLLER_EXECUTION_TIME = 1000;

export function wasmControllerGetRepresentation(processorName) {
  return WasmViewerController.getInstance().getRepresentation(processorName);
}

/**
 * Executes a WebAssembly controller with the given request.
 *
 * @param {Object} request - The request object to be executed by the controller.
 * @param {Object} options - The options for the execution.
 * @param {Function} options.onControllerExecution - The controller execution callback params.
 * @param {Function} options.onControllerExecution.fn - The callback function to be called after the execution.
 * @param {boolean} options.onControllerExecution.waitForControllerExecution - Indicates whether to wait for the controller execution to complete.
 * @param {number} options.onControllerExecution.estimatedControllerExecutionTime - The estimated time for the controller execution.
 * @param {boolean} checkStatusCode - Indicates whether to check the status code of the response.
 * @returns {Object} - The response object from the controller execution.
 */
export function wasmControllerExecute(request, { onControllerExecution, checkStatusCode = true } = {}) {
  if (window.Module) {
    const response = WasmViewerController.getInstance().execute(request);
    console.log(`${request.Processor}.${request.Action}:`, { response })
    if (!checkStatusCode) return response;
    if (response.status !== 200) {
      const action = request?.Action;
      console.log(action + ' failed with error: ' + response.message);
    }
    const {
      fn: executionCallback = null,
      waitForControllerExecution = false,
      estimatedControllerExecutionTime = ESTIMATED_CONTROLLER_EXECUTION_TIME,
    } = onControllerExecution || {};
    if (executionCallback) {
      if (waitForControllerExecution) {
        setTimeout(() => {
          executionCallback(response);
        }, estimatedControllerExecutionTime);
      } else {
        executionCallback(response);
      }
    }
    return response;
  }
  return response;
}

export function changeWasmView(new_view) {
  const req = {
    Processor: PROCESSOR.PredefinedView,
    Action: new_view,
  };
  wasmControllerExecute(req);
}

export function changeWasmArch(arch_view) {
  let view = VISIBILITY_MAPPING?.[arch_view];
  const req = {
    Processor: PROCESSOR.Visibility,
    Action: WASM_ACTION.Visibility.Update,
    ...view,
  };
  wasmControllerExecute(req);
}

export function toggleWasmOption(option, toggle) {
  switch (option) {
    case 'gingiva':
      toggleGingiva(toggle);
      break;
    case 'grid':
      toggleGrid(toggle);
      break;
    case 'superimposition':
      toggleSuperimposition(toggle);
      break;
    case 'occlusion':
      toggleOcclusion(toggle);
      break;
    case 'animation':
      toggleAnimation(toggle);
      break;
    case 'rsc':
      toggleRSC(toggle);
      break;

    default:
      break;
  }
}

function toggleGingiva(toggle) {
  const req = {
    Processor: PROCESSOR.Visibility,
    Action: WASM_ACTION.Visibility.Update,
    GingivaVisible: toggle,
  };
  wasmControllerExecute(req);
}

function toggleGrid(toggle) {
  const action = toggle ? WASM_ACTION.Grid.Show : WASM_ACTION.Grid.Hide;
  const req = {
    Action: action,
    Processor: PROCESSOR.Grid,
  };
  wasmControllerExecute(req);
}

function toggleSuperimposition(toggle) {
  const action = toggle ? WASM_ACTION.Comparison.ShowSuperimposition : WASM_ACTION.Comparison.HideSuperimposition;
  const req = {
    Action: action,
    Processor: PROCESSOR.Comparison,
  };
  wasmControllerExecute(req);
}

function toggleOcclusion(toggle) {
  const action = toggle ? WASM_ACTION.Occlusalgram.Show : WASM_ACTION.Occlusalgram.Hide;
  const req = {
    Action: action,
    Processor: PROCESSOR.Occlusalgram,
  };
  wasmControllerExecute(req);
}

export function toggleRSC(toggle) {
  const action = toggle ? WASM_ACTION.RSC.On : WASM_ACTION.RSC.Off;
  const req = {
    Action: action,
    Processor: PROCESSOR.RSC,
  };
  wasmControllerExecute(req);
}

function convertObjectValues2Float(object) {
  const obj = {};
  Object.keys(object).forEach((k) => (obj[k] = parseFloat(object[k]) || 0));
  return obj;
}

export function updateFinalMovementTableValues(values) {
  const req = {
    Action: WASM_ACTION.MovementTable.Apply,
    Processor: PROCESSOR.MovementTable,
    Tooth: convertObjectValues2Float(values),
  };
  wasmControllerExecute(req);
}

export function updateMovementTableValues(values) {
  const req = {
    Action: WASM_ACTION.MovementTable.Preview,
    Processor: PROCESSOR.MovementTable,
    Tooth: convertObjectValues2Float(values),
  };
  wasmControllerExecute(req);
}

export function getMovementTable() {
  const res = wasmControllerGetRepresentation(PROCESSOR.MovementTable);
  return { teeth: res.Teeth, incrDelta: { Angular: res.AngularIncrement, Spacial: res.SpacialIncrement } };
}

export function changeWasmStep(step) {
  const req = {
    Action: WASM_ACTION.CaseInfo.SetActiveStage,
    ActiveStageIndex: step,
    Processor: PROCESSOR.CaseInfo,
  };
  wasmControllerExecute(req);
}

export function toggleAnimation(toggle) {
  const action = toggle ? WASM_ACTION.Animation.Play : WASM_ACTION.Animation.Stop;
  const req = {
    Action: action,
    Processor: PROCESSOR.Animation,
  };
  wasmControllerExecute(req);
}

export function getWasmAnimationState() {
  const res = wasmControllerGetRepresentation(PROCESSOR.Animation);
  return res;
}

export function getHistoryState() {
  const res = wasmControllerGetRepresentation(PROCESSOR.History);
  return {
    redo: res.RedoEnabled,
    undo: res.UndoEnabled,
  };
}

export function wasmClearHistory() {
  const req = {
    Processor: PROCESSOR.History,
    Action: WASM_ACTION.History.Clear,
  };
  wasmControllerExecute(req);
}

export function wasmUndo() {
  const req = {
    Processor: PROCESSOR.History,
    Action: WASM_ACTION.History.Undo,
  };
  wasmControllerExecute(req);
}

export function wasmRedo() {
  const req = {
    Processor: PROCESSOR.History,
    Action: WASM_ACTION.History.Redo,
  };
  wasmControllerExecute(req);
}

export function updateWasmIPRValue(values) {
  const req = {
    Processor: PROCESSOR.IPRTable,
    Action: WASM_ACTION.IPR.Changed,
    IPR: values,
  };
  wasmControllerExecute(req);
}

export function getIprTable() {
  const res = wasmControllerGetRepresentation(PROCESSOR.IPRTable);
  return res.IPR;
}

export function disableRSC() {
  const req = {
    Processor: PROCESSOR.RSC,
    Action: WASM_ACTION.RSC.Off,
  };
  wasmControllerExecute(req);
}

export function setSelectedTooth(tooth_fdi) {
  const req = {
    Action: WASM_ACTION.MovementTable.SetSelectedTooth,
    Processor: PROCESSOR.MovementTable,
    SelectedFDI: tooth_fdi,
  };
  wasmControllerExecute(req);
}

export function toggleIPLabels(show) {
  const action = show ? WASM_ACTION.IPLabels.Show : WASM_ACTION.IPLabels.Hide;
  const req = {
    Action: action,
    Processor: PROCESSOR.IPLabels3d,
  };
  wasmControllerExecute(req);
}

export function getTreatmentPlanList() {
  const res = wasmControllerGetRepresentation(PROCESSOR.CaseInfo);
  return res.TreatmentPlans || [];
}

export function getCaseInfoState() {
  const res = wasmControllerGetRepresentation(PROCESSOR.CaseInfo);
  return res;
}

export function setWasmActiveTreatmentPlan(plan_index, callback) {
  const req = {
    Processor: PROCESSOR.CaseInfo,
    Action: WASM_ACTION.CaseInfo.SetActivePlan,
    ActiveTreatmentPlanIndex: plan_index,
  };
  wasmControllerExecute(req, {
    onControllerExecution: {
      fn: callback,
      waitForControllerExecution: true,
    },
  });
}

export function deleteCurrentTreatmentPlan() {
  const req = {
    Action: WASM_ACTION.CaseInfo.DeleteActivePlan,
    Processor: PROCESSOR.CaseInfo,
  };
  wasmControllerExecute(req);
}

export function createNewTreatmentPlan(new_name) {
  const req = {
    Processor: PROCESSOR.CaseInfo,
    Action: WASM_ACTION.CaseInfo.SaveActivePlanAs,
    NewName: new_name,
  };
  return wasmControllerExecute(req);
}

export function wasmSaveZip() {
  const req = {
    Action: WASM_ACTION.OpenCase.Save,
    Processor: PROCESSOR.OpenCase,
  };
  return wasmControllerExecute(req);
}

export function wasmLoadMetadata() {
  const req = {
    Action: WASM_ACTION.History.GetMetadata,
    Processor: PROCESSOR.History,
  };
  return wasmControllerExecute(req, { checkStatusCode: false });
}

export function lockCurrentTreatmentPlan() {
  const req = {
    Action: WASM_ACTION.CaseInfo.LockActivePlan,
    Processor: PROCESSOR.CaseInfo,
  };
  wasmControllerExecute(req);
}

export function resetCurrentTreatmentPlan(name) {
  const req = {
    Processor: PROCESSOR.CaseInfo,
    Action: WASM_ACTION.CaseInfo.RestorePlanFromParent,
  };
  wasmControllerExecute(req);
}

export function getRscState() {
  const res = wasmControllerGetRepresentation(PROCESSOR.RSC);
  return res;
}

export function toggleGapClosure(toggle) {
  const action = toggle ? WASM_ACTION.RSC.GapClosureOn : WASM_ACTION.RSC.GapClosureOff;
  const req = {
    Action: action,
    Processor: PROCESSOR.RSC,
  };
  wasmControllerExecute(req);
}

export function toggleLocalInfluence(isOn) {
  const action = isOn ? WASM_ACTION.RSC.LocalInfluenceOn : WASM_ACTION.RSC.LocalInfluenceOff;
  const req = {
    Action: action,
    Processor: PROCESSOR.RSC,
  };
  wasmControllerExecute(req);
  const archBasedMovementReq = {
    Processor: PROCESSOR.EditingMode,
    Action: WASM_ACTION.EditingMode.UpdateCompass,
    [WASM_PROP.EditingMode.CompassXArchBased]: !isOn,
  };
  wasmControllerExecute(archBasedMovementReq);
}

/**
 * Sets WASM bg color
 * @param color {String} Color in hex format
 */
export function setWasmBgColor(color) {
  const rgbColor = ColorConverter.toRGB(color);
  if (!rgbColor) {
    return;
  }
  const req = {
    Action: WASM_ACTION.ColorTheme.UpdateColors,
    BackgroundColor: rgbColor,
    Processor: PROCESSOR.ColorTheme,
  };
  wasmControllerExecute(req);
}

export function getEditingModeState() {
  const res = wasmControllerGetRepresentation(PROCESSOR.EditingMode);
  return res;
}

export function toggleEditingMode(Mode) {
  const req = {
    Action: WASM_ACTION.EditingMode.Switch,
    Mode,
    Processor: PROCESSOR.EditingMode,
  };
  wasmControllerExecute(req);
}

export function incMovement(newValue) {
  const values = {
    ...INC_MOVEMENT,
    ...newValue,
  };
  const req = {
    Action: WASM_ACTION.MovementTable.IncrementalMovement,
    Processor: PROCESSOR.MovementTable,
    Tooth: values,
  };
  wasmControllerExecute(req);
}

export function toggleIpLabelReadOnly(isReadOnly) {
  const req = {
    Action: WASM_ACTION.IPLabels.SetState,
    Processor: PROCESSOR.IPLabels3d,
    ReadOnly: isReadOnly,
  };
  wasmControllerExecute(req);
}

export function toggleIpr(isIpr) {
  toggleEditingMode(WASM_PROP.EditingMode.SelectTeeth);
  toggleIPLabels(isIpr);
  toggleIpLabelReadOnly(true);
}

export function toggleMaintainContacts() {
  toggleEditingMode(WASM_PROP.EditingMode.MaintainContacts);
  toggleIPLabels(true);
  toggleIpLabelReadOnly(false);
}

export function toggleAutoAdjust() {
  toggleEditingMode(WASM_PROP.EditingMode.CompassAndAutoAdjustIPR);
  toggleIPLabels(true);
  toggleIpLabelReadOnly(false);
}

function getWasmPlanComparison(planIndex) {
  if (planIndex >= 1) {
    const req = {
      Action: WASM_ACTION.Comparison.Compare,
      ComparisonPlan: planIndex,
      ComparisonStage: ETreatmentPlanStage.Ideal,
      Processor: PROCESSOR.Comparison,
      ReferencePlan: planIndex - 1,
      ReferenceStage: ETreatmentPlanStage.Ideal,
    };
    return wasmControllerExecute(req);
  }
  return {};
}

export function getBoltonAnalysisValues() {
  const res = wasmControllerGetRepresentation(PROCESSOR.BoltonAnalysis);
  return res;
}

export function getIPRAndTMTTablesDifference(activeTreatmentPlan) {
  const { Teeth } = getWasmPlanComparison(activeTreatmentPlan);

  if (!Teeth) {
    return {};
  }

  const mapStringsToFloat = (t, index) => {
    const { Delta, Parent, Self } = t.IPR || { Delta: '0', Parent: '0', Self: '0' };
    const iprDelta = parseFloat(Delta);
    const iprParent = parseFloat(Parent);
    const iprSelf = parseFloat(Self);
    const mapValuesToFloat = (obj) => {
      const res = {};
      for (let k in obj) {
        const val = parseFloat(obj[k]);
        if (val !== 0) {
          res[k] = val;
        }
      }
      return res;
    };
    return {
      ...t,
      IPR: { ...t.IPR, Delta: iprDelta, Parent: iprParent, Self: iprSelf },
      TransformsDelta: mapValuesToFloat(t[WASM_PROP.Comparison.TransformsDelta]),
      index: index <= 15 ? index + 1 : 48 - index,
    };
  };
  const hasIPRChange = (t) => {
    return t.IPR.Delta !== 0;
  };
  const hasTMTChange = (t) => {
    const tmtDelta = Object.values(t.TransformsDelta || {});
    return tmtDelta.length && tmtDelta.every((v) => v !== 0);
  };
  const hasTeethChange = (t) => hasIPRChange(t) || hasTMTChange(t);

  const tmtDiff = {};
  const iprDiff = {};
  const changedTeeth = Teeth.map(mapStringsToFloat).filter(hasTeethChange);
  changedTeeth.forEach((t, i) => {
    if (hasIPRChange(t)) {
      iprDiff[t.index] = t.IPR.Delta;
    }
    if (hasTMTChange(t)) {
      const prop = t[WASM_PROP.MovementTable.AlphaNumID];
      tmtDiff[prop] = {};
      const tmt = t[WASM_PROP.Comparison.TransformsDelta];
      for (let k in tmt) {
        tmtDiff[prop][k] = tmt[k];
      }
    }
  });
  return { tmt: tmtDiff, ipr: iprDiff };
}

export function getMandibleShiftValues() {
  return wasmControllerGetRepresentation(PROCESSOR.MandibleShift);
}

export function setMandibleShiftIgnored(ignored) {
  const req = {
    Processor: PROCESSOR.MandibleShift,
    Action: WASM_ACTION.MandibleShift.SetIgnored,
    [WASM_PROP.MandibleShift.Ignored]: ignored,
  };
  return wasmControllerExecute(req);
}

/**
 * Resets the IP (Image Processing) labels.
 *
 * @param {Object} options
 * @param {boolean} options.resetSpaces - Indicates whether to reset the spaces, default is false.
 *   If resetSpaces is set to false, executing the action will reset IPRs only.
 *   If resetSpaces is set to true, executing the action will reset IPRs and spaces
 * @param {string} options.jaw - The jaw to reset options are Upper, Lower or Both, default is Both
 */
export function resetIP({ resetSpaces = false, jaw = 'Both' } = {}) {
  const req = {
    Action: WASM_ACTION.IPLabels.ResetIP,
    Processor: PROCESSOR.IPLabels3d,
    [WASM_PROP.IPLabels.Jaw]: jaw,
    [WASM_PROP.IPLabels.ResetSpaces]: resetSpaces,
  };
  return wasmControllerExecute(req);
}
/**
 * Toggles the multi selection mode.
 * @param {Boolean} allow
 */
export function toggleMultiSelection(allow = false) {
  const req = {
    Processor: PROCESSOR.Configurer,
    Action: WASM_ACTION.Configurer.Selection,
    Allow: true,
    AllowMultiSelection: allow,
  };
  wasmControllerExecute(req);
}
/**
 * Toggles the restriction notification.
 * @param {Boolean} enable
 */
export function toggleRestrictionNotification(enable = false) {
  const req = {
    Processor: PROCESSOR.Configurer,
    Action: WASM_ACTION.Configurer.ErrorHandler,
    EnableRestrictionNotification: enable,
  };
  wasmControllerExecute(req);
}
